import { createTheme, ThemeProvider } from "@mui/material";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import { AppRoutes, PrivateRoutes } from "./routes/route";
import Login from "./views/Login";
import Projects from "./views/Projects";
import Tags from "./views/Tags";
import Users from "./views/Users";
import { Toaster } from "./utils/Toaster";
const theme = createTheme({
  typography: {
    fontFamily: [
      "DM Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
function App() {
  const { verifyToken } = useAuth()
  const user = localStorage.getItem('name');
  useEffect(() => {
    verifyToken()
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {AppRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          <Route element={!user ? <Outlet /> : <Navigate to="/dashboard" />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={user ? <Outlet /> : <Navigate to="/login" />}>
            {PrivateRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
      <Toaster />
    </ThemeProvider>
  );
}

export default App;
