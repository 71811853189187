import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useForm } from 'react-hook-form'
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom'
import { Service } from '../config/service'
import useAuth from '../hooks/useAuth'
import { ErrorToaster, SuccessToaster } from '../utils/Toaster'
const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#03C9D7"
      }
    }
  }
const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({});
  const { signIn } = useAuth()
  const submitForm = async (formData) => {
    try {
      let obj={
        email:formData.email,
        password:formData.password,
      }
      const { responseCode, status, message, data} = await Service.login(obj)
      if(status === true && responseCode === 200){
        const { role } = jwt_decode(data.token);
        if(role==='62baa24f00406999b9dec2dd'){
            signIn(data.name, data.token,data.image)
            SuccessToaster(message)
        }
        else{
            ErrorToaster("You Are Not Authorized To Access")
          }
      }
      
    }
     catch (error) {
        ErrorToaster(error)
    }

  }

    return (
        <>
            <Grid container >
                <Grid item lg={6} md={12} xs={12}>
                    <Box sx={{ position: 'relative', background: 'white' }}>
                    <Box sx={{ px: '32px',pt:'30px' }}>
                                <img src='https://demos.wrappixel.com/premium-admin-templates/react/flexy-react/docs/assets/images/logo-dark.svg' 
                                alt='logo' width='20%' />
                            </Box>
                        <Box sx={{ position: 'relative', height: '100vh', right: '-32px',display:'flex'  }}>
                           
                            <img src='https://demos.wrappixel.com/premium-admin-templates/react/flexy-react/main/static/media/login-bg.26a96a34.svg' alt='login'
                                width='100%' max-width='812px'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                    <Grid container
                        sx={{ display: 'flex', justifyContent: 'center', alignItems:'center',height:'100vh',
                             flexFlow: 'row wrap', width: '100%' }}>
                        <Grid item xl={6} lg={9} md={12} xs={12}>
                            <Box sx={{ p: '32px' }}>
                                <Typography
                                    variant='h2'
                                    sx={{ fontSize: '1.5rem', fontWeight: '700' }}>
                                    Welcome to Photo Saving
                                </Typography>
                                <Box component={'form'} onSubmit={handleSubmit(submitForm)} sx={{ mt: '40px' }}>
                                    <label htmlFor='email'>Email Address</label>
                                    <FormControl fullWidth sx={{ mt: 1, mb: 3 }}>
                                        <TextField sx={ style } placeholder="Enter E-mail"
                                        {...register('email', {
                                            required: "Please Enter Email",
                                          })} />
                                          {errors.email && 
                                          <span style={{ color: 'red',marginBottom:'15px' }} role="alert">
                                            {errors.email.message}
                                            </span>}
                                    </FormControl>
                                    <label htmlFor='email'>Password</label>
                                    <FormControl fullWidth sx={{ mt: 1, mb: 3 }}>
                                        <TextField sx={ style } type='password' placeholder="Enter Password"
                                        {...register('password', {
                                            required: "Please Enter Password",
                                          })} />
                                           {errors.password && 
                                           <span style={{ color: 'red' }} role="alert">
                                            {errors.password.message}
                                            </span>}
                                    </FormControl>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box >
                                            <FormGroup>
                                                <FormControlLabel control={
                                                <Checkbox 
                                                style ={{ color: "#03C9D7"}}
                                                 defaultChecked />} 
                                                 label="Remember this Device" />
                                            </FormGroup>
                                        </Box>
                                        <Box>
                                            <Link to='/' style={{textDecoration:'none'}}>
                                            <Typography
                                                sx={{m:1,color: '#03C9D7', fontWeight: '500', fontSize: '1rem' }}>Forget Password ?</Typography>
                                            </Link>
                                        </Box>
                                    </Box>
                                    <Button type='submit'
                                        sx={{
                                            mt:3,
                                            background: '#FB9678', color: 'white', width: '100%',
                                            borderRadius: '5px', p: '10px 22px',
                                            '&:hover': {
                                                backgroundColor: '#E88971',
                                                color: 'white',
                                            },
                                        }}>
                                        Login
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default Login