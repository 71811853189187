import { Apis, post,get, put,deleted} from '.';

export const Service = {
    login: async (obj) => {
        let result = await post(Apis.login, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    register: async (obj) => {
        let result = await post(Apis.register, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getUsers: async (search,to,from,status,token) => {
        let result = await get(Apis.getUsers+`?search=${search}&to=${to}&from=${from}&status=${status}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    delete_UserAccount:async (_id,token) => {
        let result = await deleted(Apis.delete_UserAccount+`?_id=${_id}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    get_UserAccount:async (_id,token) => {
        let result = await get(Apis.get_UserAccount+`?_id=${_id}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    update_UserAccount:async (_id,data,token) => {
        let result = await put(Apis.update_UserAccount+`?_id=${_id}`,data,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    get_Roles:async (token) => {
        let result = await get(Apis.get_Roles,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProjects:async (search,to,from,status,created_By,token) => {
        let result = await get(Apis.getProjects+`?search=${search}&to=${to}&from=${from}&status=${status}&created_By=${created_By}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    get_Project:async (_id,token) => {
        let result = await get(Apis.get_Project+`?_id=${_id}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    update_Project:async (_id,data,token) => {
        let result = await put(Apis.update_Project+`?_id=${_id}`,data,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    delete_Project:async (_id,token) => {
        let result = await deleted(Apis.delete_Project+`?_id=${_id}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    get_Tags:async (search,to,from,status,token) => {
        let result = await get(Apis.get_Tags+`?search=${search}&to=${to}&from=${from}&status=${status}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    get_Tag:async (_id,token) => {
        let result = await get(Apis.get_Tag+`?_id=${_id}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    update_Tag:async (_id,data,token) => {
        
        let result = await put(Apis.update_Tag+`?_id=${_id}`,data,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    delete_Tag:async (_id,token) => {
        let result = await deleted(Apis.delete_Tag+`?_id=${_id}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getPictureByProject:async (_id,token) => {
        let result = await get(Apis.getPictureByProject+`?_id=${_id}`,token);
        if (result.status === 200) return result.data;
        else throw result;
    },


}