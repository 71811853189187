import { Avatar, Box, Button, Card, CardContent,Chip, CircularProgress, Collapse, Divider, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Breadcrumb from '../components/breadcrumbs/Breadcrumb'
import Layout from '../layout/Layout'
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Service } from '../config/service';
import moment from 'moment'
import { ErrorToaster, SuccessToaster } from '../utils/Toaster';
import { Link } from 'react-router-dom';
const style = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#03C9D7",
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  }
}
const Users = () => {
  const token = localStorage.getItem('token') || ''
  const [users, setUsers] = useState();
  const [search, setSearch] = useState('');
  const [Status, setStatus] = useState('all');
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setUsers()
    try {
      const { responseCode, status, message, data } = await Service.getUsers(search, to, from, Status, token)
      if (status === true && responseCode === 200) {
        const mutateData = []
        for (let index = 0; index < data?.users.length; index++) {
          const element = data?.users[index];
          let transformData = {
            ...element, id: index + 1
          }
          mutateData.push(transformData)
        }
        setUsers(mutateData)
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Pagination
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Dense
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  // handle to and from
  const handleChangeTo = (newValue) => {
    setTo(moment(newValue).format("YYYY-MM-DD"));
  };
  const handleChangeFrom = (newValue) => {
    setFrom(moment(newValue).format("YYYY-MM-DD"));
  };
  // handle filter
  const [filter, setFilter] = useState(false);
  const handleFilter = () => {
    setFilter(!filter);
  };
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // delete
  const [user, setUser] = useState(false);
  const confirmDelete = (item) => {
    handleOpen()
    setUser(item)
  }
  const handleDelete = async (user) => {
    try {
      const { responseCode, status, message } = await Service.delete_UserAccount(user._id, token)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        getUsers()
      }
    } catch (error) {
      ErrorToaster(error)
      console.log(error)
    }finally{
      setOpen(false)
    }
  };
  return (
    <Layout>
      <Breadcrumb />
      <Typography
        variant="h1"
        sx={{
          m: "15px 0px",
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "1.875rem",
        }}
      >
        Users List
      </Typography>
      {/* Filter*/}
      <Box sx={{ width: '100%' }}>
        <Card sx={{ minWidth: 275, p: 1 }}>
          <CardContent>
            <Box sx={{ mb: 2.5 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2.5,
                }}
              >
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Filter
                </Typography>
               <Box>
               <IconButton onClick={getUsers}>
                  <RefreshIcon/>
                </IconButton>
                <IconButton onClick={handleFilter}>
                  {filter ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}

                </IconButton>
               </Box>
              </Box>
              <Collapse orientation="vertical" in={filter}>
                <FormControl sx={{ width: '28%' }}  >
                  <TextField sx={style} size='small' placeholder='Search By Name Or Email'
                    onChange={(event) => setSearch(event.target.value)} />
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment} >
                  <FormControl sx={{ ml: 2, width: '20%' }} >
                    <DesktopDatePicker
                      label="From"
                      value={from|| null}
                      inputFormat="DD/MM/yyyy"
                      onChange={handleChangeFrom}
                      renderInput={(params) => <TextField sx={style} {...params} size='small' />}
                    />
                  </FormControl>
                  <FormControl sx={{ ml: 2, width: '20%' }}  >
                    <DesktopDatePicker
                      label="To"
                      name='to'
                      value={to|| null}
                      inputFormat="DD/MM/yyyy"
                      onChange={handleChangeTo}
                      renderInput={(params) => <TextField sx={style} {...params} size='small' />}
                    />
                  </FormControl>
                </LocalizationProvider>
                <FormControl sx={{ ml: 2, width: '15%' }} >
                  <InputLabel sx={style}  >Status</InputLabel>
                  <Select size='small'
                    sx={style.select}
                    value={Status}
                    name="status"
                    label="Status"
                    onChange={(event) => setStatus(event.target.value)}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"is_Active"}>Active</MenuItem>
                    <MenuItem value={"deleted"}>Deleted</MenuItem>
                  </Select>
                </FormControl>
                <Button sx={{
                  ml: 2, bgcolor: '#03C9D7', color: 'white',
                  '&:hover': {
                    backgroundColor: '#07b6bf',
                  }
                }} onClick={getUsers}>Search</Button>
              </Collapse>
            </Box>
            {/* Table */}
            {users?
            users.length > 0 ?
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align='center' >Name</TableCell>
                        <TableCell align='center'>Email</TableCell>
                        <TableCell align='center'>Last Login</TableCell>
                        <TableCell align='center' >Status</TableCell>
                        <TableCell align='center'>Action</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                        return (

                          <TableRow
                            key={i + 1}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {item.id}
                            </TableCell>
                            <TableCell  align='center'>
                              <Box sx={{ display: 'flex', }}>
                              <Avatar alt="user image" src={item.image} />
                              <Box sx={{ m: 1 }}>{item.name}</Box>
                              </Box>
                            </TableCell>
                            <TableCell align='center'>{item.email}</TableCell>
                            <TableCell align='center'>{item.last_Login_IP}</TableCell>
                            <TableCell align='center'>
                              <Chip label={item.is_Deleted ? "Deleted" : "Active"} color={item.is_Deleted ? "error" : "primary"} />
                            </TableCell>
                            <TableCell align='center'>
                              <IconButton component={Link} to={`/edit/users/_id=${item._id}&name=${item.name}`}>
                                <EditIcon />
                              </IconButton>

                              <IconButton disabled={item.is_Deleted} onClick={() => confirmDelete(item)}>
                                <DeleteOutlineIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25,50]}
                  component="div"
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
              :
              <Typography variant="h6" component="h2" 
              sx={{display:'flex',justifyContent:'center'}}>No User Found</Typography>
              :
              <Box sx={{ display: 'flex',justifyContent:'center' }}>
                <CircularProgress />
              </Box>}
          </CardContent>
        </Card>
      </Box>
      {/* Modal */}
      <Modal open={open}>
        <Box sx={style.modal}>
          <Typography variant="h6" component="h2">
            Are You Sure You Want To Delete This User ?
          </Typography>
          <Divider />
          <Box sx={{ m: 2 }}>
            <Button sx={{
              color: 'white', bgcolor: '#03C9D7',
              '&:hover': {
                backgroundColor: '#07b6bf',
              },
              mr: 2
            }} onClick={() => handleDelete(user)}>
              Yes
            </Button>
            <Button sx={{
              color: 'white', bgcolor: '#D32F2F',
              '&:hover': {
                backgroundColor: '#c12a2a',
              },
            }} onClick={handleClose}>

              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  );
};

export default Users;
