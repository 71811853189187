import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const Breadcrumb = () => {
  let { pathname } = useLocation();
  let path = pathname.substring(1).split("/")
  
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      <Link to="/">
        Home
      </Link>
      {path.map((item, i) => (
        <Typography key={i} color="text.primary" 
        sx={{ textTransform: 'capitalize' }}>
          {
          item[0]==="_"?
          item.replace(/_id=[\w]*&name=/g, '').replace(/%20/,' ')
          :
          item.replace(/-/, ' ').replace(/%20/,' ')
          }
          
          </Typography>
      ))}
    </Breadcrumbs>
  )
}

export default Breadcrumb