import react,{Fragment, useEffect,useState} from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Avatar, Button, Container, Menu, MenuItem } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useLocation, Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import jwt_decode from "jwt-decode";
const drawerWidth = 265;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNav({ data }) {
  let {pathname} = useLocation();
  const { user } = useAuth()
  const firstName  = localStorage.getItem('name').split(" ");
  const token = localStorage.getItem('token') || ''()
  const {email, _id } = jwt_decode(token);
 
  const list = [
    {
      name:"Dashboard",
    children:[
      {name: "Analytical", path: "/dashboard" },
      
    ]
    },
    {
      name:"Users",
    children:[
      { name: "Users List", path: "/users-list" },
    ]
    },
    {
      name:"Tags",
    children:[
      { name: "Tags List", path: "/tags-list" },
    ]
    },
    {
      name:"Projects",
    children:[
      { name: "Projects List", path: "/projects-list" },
    ]
    }
  ];
  const [open, setOpen] = useState(true);
  const [down, setDown] = useState(false);
    const { signOut } = useAuth();

    const logOut= ()=>{
        signOut();
    }

  const handleDrawer = () => {
    setOpen(!open);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const opendropdown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" style={{ background: '#FAFBFB', boxShadow: 'none'}} open={open} >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}> 
          <Box sx={{ display: "flex" }}>
            {/* <IconButton

              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              sx={{
                marginRight: 5,
                ...open,
              }}
            >
              <MenuIcon />
            </IconButton> */}
            {/* <Typography variant="h6" noWrap component="div">
              Photo Saving (Admin Portal)
            </Typography> */}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

            <Tooltip title="Account settings">
              
              <IconButton
              sx={{borderRadius: "10px",}}
                onClick={handleClick}
                size="small"
                aria-controls={opendropdown ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={opendropdown ? 'true' : undefined}
              >
                <Avatar src={user?.image} sx={{ width: 30, height: 30,mr:1}}/>
            <Typography variant="h6" sx={{fontSize:'1rem'}}>Hi,{firstName[0]}</Typography>
              </IconButton>

            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={opendropdown}
            onClose={handleClose}
            onClick={handleClose}
            
            PaperProps={{
              elevation: 0,
              sx: {
                width:'400px',
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 80,
                  height: 80,
                  ml: 1,
                  mr: 1,
                  
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Typography sx={{
              fontSize:'1.125rem',fontWeight:500,
              p:1.5
              }}>User Profile</Typography>
              <Box sx={{display:'flex'}}>
              <Avatar src={user?.image} sx={{borderRadius:'50px'}} /> 
              <Box sx={{mt:1}}>
              <Typography variant="h4"
              sx={{
                fontSize:'1.125rem',
                fontWeight:500
              }}>{user?.name}</Typography>
              <Typography variant="h6"
              sx={{
                fontSize:'0.875rem',
                fontWeight:500,
                color: "rgb(119, 126, 137)"
              }}>Administrator</Typography>
              <Typography 
               sx={{
                fontSize:'0.875rem',
                fontWeight:500,
                color: "rgb(119, 126, 137)"
              }}>{email}</Typography>
              </Box>
              </Box>
            <Divider sx={{p:1}} />
            <MenuItem sx={{p:2}} component={Link} to={`/edit/users/_id=${_id}&name=${user?.name}`}>
              <Box sx={{display:'flex'}}>
              <Button sx={{
                bgcolor:'#F2F6F6',
                mr:1,
                borderRadius:'10px'
              }}><PersonIcon fontSize="small" />
              </Button>
              <Box >
              <Typography variant="h5"
              sx={{
                fontSize:'1rem'
              }}>My Profile</Typography>
              <Typography variant="h6"
              sx={{
                fontSize:'0.875rem',
                color:'rgb(119, 126, 137)'
              }}>Account Settings</Typography>
              </Box>
              </Box>
            </MenuItem>
            <Divider/>
            <MenuItem sx={{p:2}} component={Link} to={`/add-account`}>
              <Box sx={{display:'flex'}} >
              <Button 
              sx={{
                bgcolor:'#F2F6F6',
                mr:1,
                borderRadius:'10px'
              }}><PersonAddIcon fontSize="small" />
              </Button>
              <Box>
              <Typography variant="h5"
              sx={{
                fontSize:'1rem'
              }}>Account</Typography>
              <Typography variant="h6"
              sx={{
                fontSize:'0.875rem',
                color:'rgb(119, 126, 137)'
              }}>Add Another Account</Typography>
              </Box>
              </Box>
            </MenuItem>
            <Divider/>
            <Button fullWidth onClick={logOut}
            sx={{
              p:1,
              color: 'white', bgcolor: '#f19073',
              '&:hover': {
                backgroundColor: '#E88971',
              }
            }}>Logout</Button>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer  variant="permanent" open={open}>
        <DrawerHeader sx={{ justifyContent: 'center' }}>
          <img src="https://demos.wrappixel.com/premium-admin-templates/react/flexy-react/docs/assets/images/logo-dark.svg"
            alt='logo' width="106" height='38px' />
        </DrawerHeader>
        <List sx={{mx:2}}>
          {list.map((item,i)=>{
            return(
             <Fragment key={i}>
              <Typography variant="h4" 
              sx={{fontSize:'1.065rem',
              m:'32px 0px 16px',color:'#949DB2'}} >{item.name}</Typography>
              {item.children.map((text, index) => (
                <ListItem key={index}  disablePadding
                sx={pathname===text.path?{background:'#FB9678',
                borderRadius:'9px',display:'block',mb:1}:{ display:'block',mb:1}}>
                  <Link to={text.path} style={pathname===text.path?{color:'white'}:{color:'black'}}  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={pathname===text.path?{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          color:'white'
                        }:{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={text.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
             </Fragment>
            )
          })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Container >
          {data}
        </Container>
      </Box>
    </Box>
  );
}