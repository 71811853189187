import Axios from '../../axios';
import { errorHandler } from './errorHandler';

export const Apis = {
    login:'/api/user/login',
    resetPasswordRequest:'/api/user/resetPasswordRequest',
    register:'/api/user/register',
    getUsers:'/api/user/filter_User',
    get_UserAccount:'/api/user/get_UserAccount',
    update_UserAccount:'/api/user/update_UserAccount',
    delete_UserAccount:'/api/user/delete_UserAccount',
    get_Roles:'/api/role/get_Roles',
    getProjects:'/api/project/filterProject',
    get_Project:'/api/project/get_Project',
    update_Project:'/api/project/update_Project',
    delete_Project:'/api/project/delete_Project',
    get_Tags:'/api/tag/filter_Tag',
    get_Tag:'/api/tag/get_Tag',
    update_Tag:'/api/tag/update_Tag',
    delete_Tag:'/api/tag/delete_Tag',
    getPictureByProject:'/api/picture/getPictureByProject'


};

export const headers = {
    'content-type': 'application/json',
};


export const get = async (endPoint,token) => {
    try {
        const result = await Axios.get(endPoint, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const post = async (endPoint, data) => {
    try {
        const result = await Axios.post(endPoint, data);
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};
export const put= async (endPoint, data,token) => {
    try {
        const result = await Axios.put(endPoint, data, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};
export const deleted = async (endPoint,token) => {
    try {
        const result = await Axios.delete(endPoint, { headers: { 'x-access-token': token } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};