import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Pagination,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import { Service } from "../config/service";
import { Box } from "@mui/system";
const style = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#03C9D7",
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  },
};
const Pictures = () => {
  let { _id } = useParams();
  const token = localStorage.getItem("token") || "";
  const [picture, setPicture] = useState();
  useEffect(() => {
    getPictures();
  }, []);
  const getPictures = async () => {
    try {
      const { responseCode, status, message, data } =
        await Service.getPictureByProject(_id, token);
      if (status === true && responseCode === 200) {
        setPicture(data.pictures);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Layout>
      <Breadcrumb />
      <Typography
        variant="h1"
        sx={{
          m: "15px 0px",
          fontWeight: 700,
          fontSize: "1.875rem",
        }}
      >
        Pictures
      </Typography>
      {picture ? (
        <>
          <ImageList sx={{ width: "100%" }} cols={3} gap={"12px"}>
            {picture.map((item) => (
              <ImageListItem key={item._id} rows={2}>
                <img style={{height:'150px'}}
                  src={`http://localhost:8003/${item.image}?w=300&h=120&fit=crop&auto=format`}
                  srcSet={`http://localhost:8003/${item.image}?w=300&h=120&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.name}
                  loading="lazy"
                />
                <ImageListItemBar
                
                  title={
                    item.name.length > 25
                      ? item.name.slice(0, 25) + "..."
                      : item.name
                  }
                  actionIcon={
                    <Box sx={{display:'flex'}}>
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${item.name}`}
                        onClick={handleOpen}
                      >
                        <InfoIcon />
                      </IconButton>
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${item.name}`}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  }
                />
                {/* Modal */}
                <Modal open={open}>
                  <Box sx={style.modal}>
                    <Typography variant="h6">Picture Details</Typography>
                    <Box sx={{m:1,display:'flex'}}>
                      <Typography sx={{fontWeight:700}}>User: </Typography>
                      {item.user.name}
                    </Box>
                    <Box sx={{m:1,display:'flex'}}>
                      <Typography sx={{fontWeight:700}}>Device: </Typography>
                      {item.device}
                    </Box>
                    <Box sx={{m:1,display:'flex'}}>
                      <Typography sx={{fontWeight:700}}>Device Path: </Typography>
                      {item.device_path}
                    </Box>
                    <Box sx={{m:1,display:'flex'}}>
                      <Typography sx={{fontWeight:700}}>Ip Address: </Typography>
                      {item.ip_Address}
                    </Box>
                    <Box sx={{m:1,display:'flex'}}>
                      <Typography sx={{fontWeight:700}}>Tag: </Typography>
                      {item.tags.map(tag=>`${tag.tag}`)}
                    </Box>
                    <Button
                    sx={{
                      color: "white",
                      bgcolor: "#D32F2F",
                      "&:hover": {
                        backgroundColor: "#c12a2a",
                      },
                    }}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  </Box>
                  
                </Modal>
              </ImageListItem>
            ))}
          </ImageList>
          {/* <Pagination sx={{ float: "right" }} count={10} /> */}
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </Layout>
  );
};

// const itemData = [
//     {
//         image: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
//         title: 'Breakfast',
//         author: '@bkristastucchio',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
//         title: 'Burger',
//         author: '@rollelflex_graphy726',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
//         title: 'Camera',
//         author: '@helloimnik',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
//         title: 'Coffee',
//         author: '@nolanissac',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
//         title: 'Hats',
//         author: '@hjrc33',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
//         title: 'Honey',
//         author: '@arwinneil',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
//         title: 'Basketball',
//         author: '@tjdragotta',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
//         title: 'Fern',
//         author: '@katie_wasserman',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
//         title: 'Mushrooms',
//         author: '@silverdalex',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
//         title: 'Tomato basil',
//         author: '@shelleypauls',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
//         title: 'Sea star',
//         author: '@peterlaster',
//     },
//     {
//         image: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
//         title: 'Bike',
//         author: '@southside_customs',
//     },
// ];

export default Pictures;
