import { Button, Card, CardContent, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb'
import { Service } from '../../config/service'
import Layout from '../../layout/Layout'
import { ErrorToaster, SuccessToaster } from '../../utils/Toaster'
const style = {
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#03C9D7"
        }
    }
}
const ProjectEdit = () => {
    const { _id } = useParams()
    const token = localStorage.getItem('token') || ''
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm({});
    const [project, setProject] = useState();
    useEffect(() => {
        getProject()
    }, []);
    const getProject = async () => {
        
        try {
            const { responseCode, status, message, data } = await Service.get_Project(_id, token)
            if (status === true && responseCode === 200) {
                setProject(data.project)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProject(prevState => ({ ...prevState, [name]: value }));
    }
    const submitForm = async (formData) => {
        try{
            let obj = {
                name: formData.name,
                description: formData.description,
                is_Deleted:formData.is_Deleted
            }
            const { responseCode, status, message, data } = await Service.update_Project(project._id, obj, token)
                if (status === true && responseCode === 200) {
                    SuccessToaster(message)
                }
        }
        catch(error){
            ErrorToaster(error)
        }
    }
    return (
        <Layout>
            <Breadcrumb />
            <Typography variant='h1' sx={{
                m: '15px 0px',
                fontFamily: 'DM Sans', fontWeight: 700,
                fontSize: '1.875rem'
            }} >Edit Project
            </Typography>
            <Box sx={{ width: '100%' }} component={'form'} onSubmit={handleSubmit(submitForm)}>
                <Card sx={{ minWidth: 275, p: 2 }}>
                    <CardContent>
                        {project ?
                            <Box>
                                <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, mb: 2 }} variant='h6'>Edit Details</Typography>
                                <Typography sx={{ mt: 2 }}>Project Name</Typography>
                                <FormControl fullWidth>
                                    <TextField size='small' sx={style} defaultValue={project?.name || ''}
                                        {...register('name', {
                                            required: "Please Enter Project Name",
                                        })} />
                                    {errors.name && <span role="alert" style={{ color: 'red' }}>{errors.name.message}</span>}
                                </FormControl>
                                <Typography sx={{ mt: 2 }}>Project Description</Typography>
                                <FormControl fullWidth>
                                    <TextField size='small' sx={style} defaultValue={project?.description || ''}
                                        {...register('description', {
                                            required: "Please Enter Description",
                                        })} />
                                    {errors.description && <span role="alert" style={{ color: 'red' }}>{errors.description.message}</span>}
                                </FormControl>
                                <Typography sx={{ mt: 2 }}>Created By</Typography>
                                <FormControl fullWidth>
                                    <TextField size='small' sx={style} disabled
                                    defaultValue={project?.created_By || ''}/>
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="demo-simple-select-label" >Status</InputLabel>
                                    <Select
                                        sx={{ textTransform: 'capitalize' }}
                                        size='small'
                                        value={project.is_Deleted}
                                        name='is_Deleted'
                                        label="status"
                                        {...register('is_Deleted', {
                                            required: "Please Select Status",
                                        })}
                                        onChange={handleChange}
                                    >
                                                <MenuItem sx={{ textTransform: 'capitalize' }}
                                                    value={'false'}>Active
                                                </MenuItem>
                                                <MenuItem sx={{ textTransform: 'capitalize' }}
                                                    value={'true'}>Deleted
                                                </MenuItem>
                                       
                                    </Select>
                                    {errors.is_Deleted && <span role="alert" style={{ color: 'red' }}>{errors.is_Deleted.message}</span>}
                                </FormControl>
                                <Button  type='submit' sx={{
                                    float: 'right', mt: 2,
                                    bgcolor: '#03C9D7', color: 'white',
                                    '&:hover': { backgroundColor: '#07b6bf' }
                                }}>
                                    Update
                                </Button>
                            </Box>
                            :
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Box>
            </Layout>
            )
}

export default ProjectEdit