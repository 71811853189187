import { Autocomplete, Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Collapse, createFilterOptions, Divider, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Breadcrumb from '../components/breadcrumbs/Breadcrumb'
import Layout from '../layout/Layout'
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Service } from '../config/service';
import moment from 'moment'
import { ErrorToaster, SuccessToaster } from '../utils/Toaster';
import { Link } from 'react-router-dom';
const style = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#03C9D7"
    }
  },
  select: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#03C9D7',
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  }
}
const Projects = () => {
  const token = localStorage.getItem('token') || ''
  const [projects, setProjects] = useState();
  const [search, setSearch] = useState('');
  const [Status, setStatus] = useState('all');
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const [created_By, setCreated_By] = useState('');
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getProjects()
    getUsers()
  }, []);

  const getProjects = async () => {
    setProjects()
    try {
      const { responseCode, status, message, data } = await Service.getProjects(search, to, from, Status, created_By, token)
      if (status === true && responseCode === 200) {
        const mutateData = []
        for (let index = 0; index < data?.projects.length; index++) {
          const element = data?.projects[index];
          let transformData = {
            ...element, id: index + 1
          }
          mutateData.push(transformData)
        }
        setProjects(mutateData)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getUsers = async () => {
    try {
      const { responseCode, status, data } = await Service.getUsers('', '', '', '', token)
      if (status === true && responseCode === 200) {
        setUsers(data.users)
      }
    } catch (error) {
      console.log(error)
    }
  }


  //Pagination
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Dense
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  // handle to and from 
  const handleChangeTo = (newValue) => {
    setTo(moment(newValue).format('YYYY-MM-DD'))
  };
  const handleChangeFrom = (newValue) => {
    setFrom(moment(newValue).format('YYYY-MM-DD'))
  };
  // handle filter
  const [filter, setFilter] = useState(false);
  const handleFilter = () => {
    setFilter(!filter)
  }
  //   const OPTIONS_LIMIT = 5;
  // const defaultFilterOptions = createFilterOptions();

  // const filterOptions = (options, state) => {
  //   return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  // };
  const handleChange = (event, value) => {

    try {
      let created_By
      if (value) {
        created_By = value
        setCreated_By(created_By._id)
      } else {
        setCreated_By('')
      }
    }
    catch (error) {
      console.log("🚀 ~ file: Projects.js ~ line 135 ~ handleChange ~ error", error)


    }
  }
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // delete
  const [project, setProject] = useState(false);
  const confirmDelete = (item) => {
    handleOpen()
    setProject(item)
  }
  const handleDelete = async (project) => {
    try {
      const { responseCode, status, message } = await Service.delete_Project(project._id, token)
      if (status === true && responseCode === 200) {
        SuccessToaster(message)
        getProjects()
      }
    } catch (error) {
      ErrorToaster(error)
      console.log(error)
    } finally {
      setOpen(false)
    }


  }
  return (
    <Layout>
      <Breadcrumb />
      <Typography variant='h1' sx={{
        m: '15px 0px',
        fontFamily: 'DM Sans', fontWeight: 700,
        fontSize: '1.875rem'
      }} >Projects List
      </Typography>
      {/* Table */}
      <Box sx={{ width: '100%' }}>
        <Card sx={{ minWidth: 275, p: 1 }}>
          <CardContent>
            <Box sx={{ mb: 2.5 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2.5 }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Filter
                </Typography>
                <IconButton onClick={getProjects}>
                  <RefreshIcon />
                </IconButton>
                <IconButton onClick={handleFilter}>
                  {filter ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}

                </IconButton>
              </Box>
              <Collapse orientation="vertical" in={filter}  >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <FormControl sx={{ width: '50%' }}  >
                    <TextField sx={style} size='small' placeholder='Search By Project Name'
                      onChange={(event) => setSearch(event.target.value)} />
                  </FormControl>
                  <FormControl sx={{ ml: 2, width: '50%' }}  >
                    <Autocomplete
                      // filterOptions={filterOptions}
                      size='small'
                      disableClearable
                      freeSolo
                      options={users}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => handleChange(event, value)}
                      renderInput={(params) => (
                        <TextField
                          key={2}
                          {...params}
                          onChange={handleChange}
                          placeholder="Created By"
                          sx={style}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <br />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment} >
                    <FormControl sx={{ width: '35%' }} >
                      <DesktopDatePicker
                        label="From"
                        value={from || null}
                        inputFormat="DD/MM/yyyy"
                        onChange={handleChangeFrom}
                        renderInput={(params) => <TextField sx={style} {...params} size='small' />}
                      />
                    </FormControl>
                    <FormControl sx={{ ml: 2, width: '35%' }}  >
                      <DesktopDatePicker
                        label="To"
                        name='to'
                        value={to || null}
                        inputFormat="DD/MM/yyyy"
                        onChange={handleChangeTo}
                        renderInput={(params) => <TextField sx={style} {...params} size='small' />}
                      />
                    </FormControl>
                  </LocalizationProvider>
                  <FormControl sx={{ ml: 2, width: '30%' }} >
                    <InputLabel sx={style}  >Status</InputLabel>
                    <Select size='small'
                      sx={style.select}
                      value={Status}
                      name='status'
                      label="Status"
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      <MenuItem value={'all'}>All</MenuItem>
                      <MenuItem value={'is_Active'}>Active</MenuItem>
                      <MenuItem value={'deleted'}>Deleted</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <br />
                <Button sx={{
                  float: 'right', bgcolor: '#03C9D7', color: 'white',
                  '&:hover': {
                    backgroundColor: '#07b6bf',
                  }
                }} onClick={getProjects}>Search</Button>
              </Collapse>

            </Box>

            {projects ?
              projects.length > 0 ?
                <>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell align='center'>Name</TableCell>
                          <TableCell align='center'>Description</TableCell>
                          <TableCell align='center'>Created By</TableCell>
                          <TableCell align='center'>Total Pictures</TableCell>
                          <TableCell align='center'>Total Documents</TableCell>
                          <TableCell align='center' >Status</TableCell>
                          <TableCell align='center'>Action</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => {
                          return (

                            <TableRow
                              key={i + 1}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {item.id}
                              </TableCell>
                              <TableCell align='center'>{item.name}</TableCell>
                              <TableCell align='center'>
                                {item.description.length > 15
                                  ? item.description.slice(0, 15) + '...'
                                  : item.description}
                              </TableCell>
                              <TableCell align='center'>{item.created_By}</TableCell>
                              <TableCell align='center'>{item.pictures_count}</TableCell>
                              <TableCell align='center'>{item.documents_count}</TableCell>
                              <TableCell align='center'>
                                <Chip label={item.is_Deleted ? "Deleted" : "Active"} color={item.is_Deleted ? "error" : "primary"} />
                              </TableCell>
                              <TableCell align='center'>
                                <Box sx={{display:'flex'}}>
                                <IconButton component={Link} to={`/projects/_id=${item._id}&name=${item.name}/pictures`}>
                                  <CameraAltOutlinedIcon />
                                </IconButton>
                                <IconButton component={Link} to={`/project/documents/_id=${item._id}&name=${item.name}`}>
                                  <PictureAsPdfOutlinedIcon />
                                </IconButton>
                                </Box>
                                <Box sx={{display:'flex'}}>
                                <IconButton component={Link} to={`/edit/projects/_id=${item._id}&name=${item.name}`}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton disabled={item.is_Deleted} onClick={() => confirmDelete(item)}>
                                  <DeleteOutlineIcon />
                                </IconButton>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={projects.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
                :
                <Typography variant="h6" component="h2"
                  sx={{ display: 'flex', justifyContent: 'center' }}>No Project Found</Typography>
              :
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>}
          </CardContent>
        </Card>
      </Box>
      {/* Modal */}
      <Modal
        open={open}
      >
        <Box sx={style.modal}>
          <Typography variant="h6" component="h2">
            Are You Sure You Want To Delete This Project?
          </Typography>
          <Divider />
          <Box sx={{ m: 2 }}>
            <Button sx={{
              color: 'white', bgcolor: '#03C9D7',
              '&:hover': {
                backgroundColor: '#07b6bf',
              },
              mr: 2
            }} onClick={() => handleDelete(project)}>
              Yes
            </Button>
            <Button sx={{
              color: 'white', bgcolor: '#D32F2F',
              '&:hover': {
                backgroundColor: '#c12a2a',
              },
            }} onClick={handleClose}>

              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Layout>
  )
}

export default Projects