import { Box, Button, Card, CardContent, FormControl, TextField, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import {  useNavigate } from 'react-router-dom'
import Breadcrumb from '../components/breadcrumbs/Breadcrumb'
import { Service } from '../config/service'
import Layout from '../layout/Layout'
import { ErrorToaster, SuccessToaster } from '../utils/Toaster'
const style = {
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#03C9D7"
        }
    }
}
const AddAccount = () => {
    const { register, handleSubmit,watch, reset, formState: { errors } } = useForm({});
    const password = useRef({});
  password.current = watch("password", "");
  let navigate = useNavigate()
    const submitForm = async (formData) => {
        try {
            let obj = {
                name: formData.name,
                email:formData.email,
                phone:formData.phone,
                password:formData.password
            }
            const { responseCode, status, message, data } = await Service.register(obj)
            if (status === true && responseCode === 200) {
                SuccessToaster(message)
                navigate('/')
            }
        }
        catch (error) {
            ErrorToaster(error)
        }
        
    
      }
    return (
        <Layout>
            <Breadcrumb />
            <Typography variant='h1' sx={{
                m: '15px 0px',
                fontFamily: 'DM Sans', fontWeight: 700,
                fontSize: '1.875rem'
            }} >Add Account
            </Typography>
            <Box sx={{ width: '100%' }} component={'form'} onSubmit={handleSubmit(submitForm)}>
                <Card sx={{ minWidth: 275, p: 2 }}>
                    <CardContent>
                    <Box>
                    <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, mb: 2 }} variant='h6'>Account Details</Typography>
                        <Typography sx={{ mt: 2 }}>Name</Typography>
                        <FormControl fullWidth>
                                    <TextField size='small' sx={style}
                                        {...register('name', {
                                            required: "Please Enter Name",
                                        })} />
                                    {errors.name && <span role="alert" style={{ color: 'red' }}>{errors.name.message}</span>}
                                </FormControl>
                                <Typography sx={{ mt: 2 }}>Email</Typography>
                                <FormControl fullWidth>
                                    <TextField size='small' sx={style} 
                                        {...register('email', {
                                            required: "Please Enter Email",
                                        })} />
                                    {errors.email && <span role="alert" style={{ color: 'red' }}>{errors.email.message}</span>}
                                </FormControl>
                                <Typography sx={{ mt: 2 }}>Phone</Typography>
                                <FormControl fullWidth sx={{ mb: 1 }}>
                                    <TextField type='number'
                                        sx={style}
                                        size='small'
                                        {...register('phone')} />
                                    {errors.phone && <span role="alert" style={{ color: 'red' }}>{errors.phone.message}</span>}
                                </FormControl>
                                <Typography sx={{ mt: 2 }}>Password</Typography>
                                <FormControl fullWidth sx={{ mb: 1 }}>
                                    <TextField 
                                        sx={style}
                                        size='small'
                                        type='password'
                                        {...register('password', {
                                            required: "Please Enter Password",
                                            minLength: {
                                                value: 6,
                                                message: "Password Minimum Length is 6"
                                              }
                                        })} />
                                    {errors.password && <span role="alert" style={{ color: 'red' }}>{errors.password.message}</span>}
                                </FormControl>
                                <Typography sx={{ mt: 2 }}>Confirm Password</Typography>
                                <FormControl fullWidth sx={{ mb: 1 }}>
                                <TextField
                                type='password'
                                        sx={style}
                                        size='small'
                                        {...register('confirmPassword', {
                                            required: "Please Enter Confirm Password",
                                            validate: value =>
                          value === password.current || "The Passwords Do not Match"
                                        })} />
                                    {errors.confirmPassword && <span role="alert" style={{ color: 'red' }}>{errors.confirmPassword.message}</span>}
                                </FormControl>
                                <Button  type='submit' sx={{
                                    float: 'right', mt: 2,
                                    bgcolor: '#03C9D7', color: 'white',
                                    '&:hover': { backgroundColor: '#07b6bf' }
                                }}>
                                    Add Account
                                </Button>
                    </Box>
                    </CardContent>
                </Card>
            </Box>
        </Layout>
    )
}

export default AddAccount