import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <>
            <Box sx={{
                height: '100vh', bgcolor: '#E4F5FF',
                display: 'flex', justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontSize: '1.875rem', fontWeight: 500 }}>404</Typography>
                    <Typography>This page could not be found.</Typography>
                    <Link to='/'>
                    <Button sx={{
                        mt: 2,
                        borderRadius:'5 px',
                        bgcolor: '#03C9D7', color: 'white',
                        '&:hover': { backgroundColor: '#07b6bf' }
                    }}
                    >
                        Back To Home
                    </Button>
                    </Link>
                </Box>
            </Box>

        </>
    )
}

export default NotFound