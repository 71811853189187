import { Avatar, Button, Card, CardContent, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb'
import { Service } from '../../config/service'
import useAuth from '../../hooks/useAuth'
import Layout from '../../layout/Layout'
import { ErrorToaster, SuccessToaster } from '../../utils/Toaster'
import jwt_decode from "jwt-decode";
const style = {
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#03C9D7"
        }
    }
}
const UserEdit = () => {
    const { _id } = useParams()
    const token = localStorage.getItem('token') || ''
    var decoded = jwt_decode(token);
    const {signIn } = useAuth()
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm({});
    const [editUser, setEditUser] = useState();
    const [editRole, setEditRole] = useState();
    const [roles, setRoles] = useState([]);


    useEffect(() => {
        getUser()
        getRoles()
    }, []);
    const getUser = async () => {
        try {
            const { responseCode, status, message, data } = await Service.get_UserAccount(_id, token)
            if (status === true && responseCode === 200) {
                setEditUser(data.user)
                setEditRole(data.user.role)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const getRoles = async () => {
        try {
            const { responseCode, status, message, data } = await Service.get_Roles(token)
            if (status === true && responseCode === 200) {
                setRoles(data.roles)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const hiddenFileInput = useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    // const handleImage = async (e) => {
    //     let file = e.target.files[0];
    //     let reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = async () => {
    //       user.image = reader.result
    //       setImage(reader.result)
    //     }
    //   }
    const handleChangeRole = (e) => {
        const { name, value } = e.target;
        setEditRole(prevState => ({ ...prevState, ['_id']: value }));
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditUser(prevState => ({ ...prevState, [name]: value }));
    }
    const submitForm = async (formData) => {
        try {
            let obj = {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                role: formData.role,
                is_Deleted:formData.is_Deleted
            }
            const { responseCode, status, message, data } = await Service.update_UserAccount(editUser._id, obj, token)
            if (status === true && responseCode === 200) {
                SuccessToaster(message)
                if (decoded._id===_id){
                    signIn(obj.name,token)
                }
            }
        }
        catch (error) {
            ErrorToaster(error)
        }

    }
    return (
        <Layout>
            <Breadcrumb />
            <Typography variant='h1' sx={{
                m: '15px 0px',
                fontFamily: 'DM Sans', fontWeight: 700,
                fontSize: '1.875rem'
            }} >Edit User
            </Typography>
            {/* form */}
            <Box sx={{ width: '100%' }} component={'form'} onSubmit={handleSubmit(submitForm)}>
                <Card sx={{ minWidth: 275, p: 2 }}>
                    <CardContent>
                        {editUser && roles ?
                            <Box>
                                <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, mb: 2 }} variant='h6'>Edit Details</Typography>
                                <Box sx={{ mb: 2, width: '80px', cursor: 'pointer' }}>
                                    <Avatar
                                        onClick={handleClick}
                                        alt={editUser.name}
                                        src={editUser.image}
                                        sx={{ width: 100, height: 100, border: '3px solid #07b6bf', margin: 'auto', my: '5px' }} />
                                    <input type="file"
                                        accept="image/*"
                                        ref={hiddenFileInput}
                                        //   onChange={handleImage}

                                        style={{ display: 'none' }}
                                    />
                                </Box>
                                <Typography sx={{ mt: 2 }}>Name</Typography>
                                <FormControl fullWidth>
                                    <TextField size='small' sx={style} defaultValue={editUser?.name || ''}
                                        {...register('name', {
                                            required: "Please Enter Name",
                                        })} />
                                    {errors.name && <span role="alert" style={{ color: 'red' }}>{errors.name.message}</span>}
                                </FormControl>
                                <Typography sx={{ mt: 2 }}>Email</Typography>
                                <FormControl fullWidth>
                                    <TextField size='small' sx={style} defaultValue={editUser?.email || ''}
                                        {...register('email', {
                                            required: "Please Enter Email",
                                        })} />
                                    {errors.email && <span role="alert" style={{ color: 'red' }}>{errors.email.message}</span>}
                                </FormControl>
                                <Typography sx={{ mt: 2 }}>Phone</Typography>
                                <FormControl fullWidth sx={{ mb: 1 }}>
                                    <TextField type='number'
                                        sx={style}
                                        size='small' defaultValue={editUser?.phone || ''}
                                        {...register('phone',)} />
                                    {errors.phone && <span role="alert" style={{ color: 'red' }}>{errors.phone.message}</span>}
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="demo-simple-select-label" >Role</InputLabel>
                                    <Select
                                        sx={{ textTransform: 'capitalize' }}
                                        size='small'
                                        value={editRole._id }
                                        label="role"
                                        {...register('role', {
                                            required: "Please Select Role",
                                        })}
                                        onChange={handleChangeRole}
                                    >
                                        {roles.map((role, i) => {
                                            return (
                                                <MenuItem key={i} sx={{ textTransform: 'capitalize' }}
                                                    value={role._id}>{role.role}
                                                </MenuItem>)
                                        })}
                                    </Select>
                                    {errors.role && <span role="alert" style={{ color: 'red' }}>{errors.role.message}</span>}
                                </FormControl>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="demo-simple-select-label" >Status</InputLabel>
                                    <Select
                                        sx={{ textTransform: 'capitalize' }}
                                        size='small'
                                        value={editUser.is_Deleted}
                                        name='is_Deleted'
                                        label="status"
                                        {...register('is_Deleted', {
                                            required: "Please Select Status",
                                        })}
                                        onChange={handleChange}
                                    >
                                                <MenuItem sx={{ textTransform: 'capitalize' }}
                                                    value={'false'}>Active
                                                </MenuItem>
                                                <MenuItem sx={{ textTransform: 'capitalize' }}
                                                    value={'true'}>Deleted
                                                </MenuItem>
                                       
                                    </Select>
                                    {errors.is_Deleted && <span role="alert" style={{ color: 'red' }}>{errors.is_Deleted.message}</span>}
                                </FormControl>
                                <Button type='submit' sx={{
                                    float: 'right', mt: 2,
                                    bgcolor: '#03C9D7', color: 'white',
                                    '&:hover': { backgroundColor: '#07b6bf' }
                                }}>
                                    Update
                                </Button>
                            </Box>
                            :
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Box>
        </Layout>
    )
}

export default UserEdit