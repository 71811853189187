import React from 'react'
import SideNav from './SideNav'

const Layout = ({ children }) => {
    return (
        <>
            <SideNav data={children}/>
            
           
        </>
    )
}

export default Layout