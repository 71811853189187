import { Navigate } from 'react-router-dom';
import AddAccount from '../views/AddAccount';
import Dashboard from "../views/Dashboard";
import ProjectEdit from '../views/edit/ProjectEdit';
import TagEdit from '../views/edit/TagEdit';
import UserEdit from '../views/edit/UserEdit';
import NotFound from '../views/NotFound';
import Pictures from '../views/Pictures';
import Projects from "../views/Projects";
import Tags from "../views/Tags";
import Users from "../views/Users";
// *Import Components

export const AppRoutes = [
    {
        path: "/",
        element: <Navigate to="/dashboard" />
    },
    {
        path: "*",
        element: <Navigate to="/404" />
    },
    {
        path: "/404",
        element: <NotFound />
    },
]

export const PrivateRoutes = [
    {
        path: "/dashboard",
        element: <Dashboard />
    },
    {
        path: "/add-account",
        element: <AddAccount />
    },
    {
        path: "/users-list",
        element: <Users />
    },
    {
        path: "/edit/users/_id=:_id&name:name",
        element: <UserEdit />
    },
    {
        path: "/projects-list",
        element: <Projects />
    },
    {
        path: "/edit/projects/_id=:_id&name:name",
        element: <ProjectEdit />
    },
    {
        path: "/tags-list",
        element: <Tags />
    },
    {
        path: "/edit/tags/_id=:_id&name:name",
        element: <TagEdit />
    },
    {
        path: "/projects/_id=:_id&name:name/pictures",
        element: <Pictures />
    },
   
    
]